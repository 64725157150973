<template>
   <div :style="{margin: '20px 20px'}" class="card">
    <div class="card-header">
        <p class="card-header-title is-size-6">
        Preview #{{ index + 1}}
        </p>
        <div v-if="rule.conflict">
            <b-tooltip
            :style="{margin: '10px 10px'}"
            :label="`Conflicto`"
            position="is-top"
            type="is-primary"
            size="is-big"
            animated
            rounded
            >
            <b-icon pack="fas" icon="exclamation-triangle" size="is-big" type="is-warning" ></b-icon>
            </b-tooltip>
        </div>
        </div>
        <div class="card-content">
        <p>Clients: <strong>{{ getClientGroupNames(rule.clients) }}</strong></p>
        <p>Platforms: <strong>{{ getNames(rule, 'platforms') }}</strong></p>
        <div v-if="isFirstParty">
          <p>Segment Type: <strong>{{ getNames(rule, 'segmentType') }}</strong></p>
        </div>
        <div v-else>
          <p>Clusters: <strong>{{ getNames(rule, 'clusters') }}</strong></p>
        </div>
        <p>Precio: <strong>US$ {{ rule.price }}</strong></p>
        <p>Type: <strong>{{ rule.selectedRule }}</strong></p>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    rule: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    selectedRule: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['clients', 'platforms', 'clusters', 'segmentType']),
    flattenedClients() {
      return this.clients.map(e => e.children).flat()
    },
    isFirstParty() {
      if (this.selectedRule === 'firstparty') {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions(['getClients', 'getPlatforms', 'getClusters', 'getSegmentTypes']),
    getNames(arr, param) {
      return this[param]
        .filter(item => arr[param].includes(item.id))
        .map(e => e.name)
        .join(', ')
    },
    //   // Same algorithm
    //   // return this[param].filter((item) => arr.indexOf(item.id) >= 0).map(e => e.name).join(', ')
    getClientGroupNames(array) {
      let found = []
      array.forEach(val => {
        const foundElement = this.flattenedClients.find(e => {
          return e.id === val
        })
        if (foundElement) {
          found.push(foundElement)
        }
      })
      return found.map(e => e.name).join(', ')
    }
  }
}
</script>

<style lang="">
</style>
