<template>
  <app-organization-template>
    <template v-if="isCreation" slot="title"> Crear Nueva Organización</template>
    <template v-else slot="title"> Editar Organización</template>
    <template slot="message">
      <div class="columns is-centered">
        <div class="column is-two-thirds">
          <b-message type="is-success" has-icon>
            <p v-if="isCreation" class="is-size-4">
              La organización <strong>{{ organization.name }}</strong> ha sido creada con éxito!
            </p>
            <p v-else class="is-size-4">
              La organización <strong>{{ organization.name }}</strong> fue editada con exito!
            </p>
            <p>Presiona <span class="tag is-small is-accent">Configurar Aplicaciones</span> Si deseas configurar y activarlas</p>

            <p v-if="isUserAdmin">
              Asigne los precios de la cuenta
              <a class="button is-accent is-small" @click="goToPriceCreation(id)">
                <span>Aqui</span>
              </a>
            </p>
          </b-message>
        </div>
      </div>
      <hr />
    </template>
    <template slot="content">
      <b-field grouped position="is-right">
        <p class="control">
          <router-link :to="{ name: 'organizationEdit', params: { id: `${organization.id}` } }" tag="button" class="button">
            Volver
          </router-link>
        </p>
        <p class="control">
          <router-link :to="{ name: 'organizationApps' }" tag="button" class="button is-accent"> Configurar Aplicaciones </router-link>
        </p>
      </b-field>
    </template>
  </app-organization-template>
</template>

<script>
import { mapGetters } from 'vuex'
import CommonTemplate from '@/views/templates/CommonTemplate'
import utils from '@/utils/user-utils'

export default {
  name: 'organizationConfirmation',
  components: {
    'app-organization-template': CommonTemplate
  },
  props: {
    id: {
      type: String,
      required: true
    },
    isCreation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['organization', 'loggedUser']),
    isUserAdmin() {
      return utils.isAdminUser(parseInt(this.loggedUser.id))
    }
  },
  methods: {
    goToPriceCreation(id) {
      this.$router.push({ name: 'ruleCreated', params: { id: id } })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
