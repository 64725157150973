var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal-template",
    [
      _c("template", { slot: "title" }, [_c("h1", [_vm._v("Match One")])]),
      _c("template", { slot: "content" }, [
        _vm._v(
          "\n    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quo distinctio cum laboriosam nemo aliquam, voluptatem optio accusamus alias itaque amet, nulla, pariatur possimus officia. Explicabo est nihil accusantium excepturi possimus!\n  "
        )
      ]),
      _c("template", { slot: "buttons" }, [
        _c("a", { staticClass: "button is-accent" }, [_vm._v("Aceptar")])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }