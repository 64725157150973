<template>
  <section>
    <div class="columns"></div>
    <form id="orgCreate" novalidate @submit.prevent>
      <!-- ORGANIZATION NAME  -->
      <b-field
        :type="errors.has('organization') ? 'is-danger' : ''"
        :message="errors.first('organization')"
        label="Nombre"
        class="has-text-light"
      >
        <b-input
          v-validate="'required|min:2'"
          v-model="organizationName"
          name="organization"
          placeholder="Ingrese un nombre para la organización"
        ></b-input>
      </b-field>
      <!-- END ORGANIZATION NAME -->
      <!-- ORGANIZATION NAME  -->
      <b-field
        :type="errors.has('organization') ? 'is-danger' : ''"
        :message="errors.first('organization')"
        label="Tier"
        class="has-text-light"
      >
        <b-select v-model="organizationTier" placeholder="Selecciona un Tier" expanded icon="earth">
          <option v-for="tier in [1,2,3]" :key="tier" :value="tier">Tier {{ tier }}</option>
        </b-select>
      </b-field>
      <!-- END ORGANIZATION NAME -->

      <b-field :custom-class="formatedClasses" label="Country">
        <b-select v-model="organizationCountry" placeholder="Selecciona un país" expanded icon="earth">
          <option v-for="country in countries" :key="country.id" :value="country.code">{{ country.country }}</option>
        </b-select>
      </b-field>
      <!-- PARENT ORGANIZATION AUTOCOMPLETE -->
      <b-field :message="errors.first('parentOrgName')" :type="errors.has('parentOrgName') ? 'is-danger' : ''" label="Organización Padre">
        <b-autocomplete
          v-model="parentOrgName"
          :keep-first="keepFirst"
          :open-on-focus="openOnFocus"
          :data="filteredOrganizations"
          icon="search"
          placeholder="Agregar organización padre..."
          field="name"
          name="parentOrgName"
          @select="(option) => (selectedParent = option)"
        >
          <template slot="empty">No hay resultados</template>
        </b-autocomplete>
      </b-field>
      <hr />
      <div v-if="organizationId">
        <b-field readonly label="Organizaciones hijas"></b-field>
        <template >
          <b-table :data="getChildrenOrg" :columns="columnsChildrenOrg" :paginated="isPaginated" :per-page="perPage" pagination-size="is-small">
          </b-table>
        </template>
        <hr />
        <b-field readonly label="Segments"></b-field>
        <template>
          <b-table :data="getSegments" :columns="columnsSegments" :paginated="isPaginated" :per-page="perPage" pagination-size="is-small">
          </b-table>
        </template>
        <b-field readonly label="Pricing rules"></b-field>
        <template>
          <b-table :data="getPricingRules" :columns="pricingRulesColumns" :paginated="isPaginated" :per-page="perPage" pagination-size="is-small">
          </b-table>
        </template>
        <hr />

        <b-field style="display: block; margin-top: 2%;" readonly label="Usuarios con acceso">
          <b-field position="is-left" readonly >
        <template>
          <b-table :data="getUsersByOrganization" :columns="columns" :paginated="isPaginated" :per-page="perPage" pagination-size="is-small"></b-table>
        </template>
          </b-field>
            <vue-json-to-csv
            :json-data="getUsersByOrganization"
            csv-title="users_by_organization"
            >
              <b-button type="is-success">
                <p>Descargar lista de usuarios</p>
              </b-button>
            </vue-json-to-csv>
        </b-field>
        <hr />

        <b-field readonly label="Resumen de licencias por plataforma"></b-field>

        <template>
          <b-table :data="getLicenses" :columns="columnsLicenses">
          </b-table>
        </template>
      </div>
      <hr />
      <b-field grouped position="is-right">
        <p class="control">
          <router-link :to="{ name: 'dashboard' }" class="button">
            <span>Cancelar</span>
          </router-link>
        </p>
        <p class="control">
          <a :class="{ 'is-loading': showLoader }" class="button is-accent" @click="onOrganizationCreate">
            <span>Guardar</span>
          </a>
        </p>
      </b-field>
    </form>
  </section>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
import { mapActions, mapGetters } from 'vuex'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'organizationForm',
  components: {
    VueJsonToCsv
  },
  mixins: [formMixin],
  data() {
    return {
      organizationId: null,
      organizationName: '',
      organizationTier: null,
      organizationCountry: undefined,
      countries: [],
      parentOrgName: '',
      selectedParent: null,
      openOnFocus: true,
      keepFirst: false,
      allOrganizations: [],
      files: null,
      getUsersByOrganization: [],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true
        },
        {
          field: 'username',
          label: 'Username'
        },
        {
          field: 'name',
          label: 'Name'
        },
        {
          field: 'email',
          label: 'Email'
        }
      ],
      isPaginated: true,
      perPage: 10,
      getLicenses: [],
      columnsLicenses: [
        {
          field: 'platform',
          label: 'Platforma'
        },
        {
          field: 'count',
          label: 'Número de licencias'
        }
      ],
      getPricingRules: [],
      pricingRulesColumns: [
        {
          field: 'idRule',
          label: 'ID',
          width: '40',
          numeric: true
        },
        {
          field: 'platform.name',
          label: 'Platform'
        },
        {
          field: 'cluster.name',
          label: 'Cluster'
        },
        {
          field: 'segmentType.name',
          label: 'Segment Type'
        },
        {
          field: 'price',
          label: 'Price'
        }
      ],
      getChildrenOrg: [],
      columnsChildrenOrg: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true
        },
        {
          field: 'name',
          label: 'Name'
        }
      ],
      getSegments: [],
      columnsSegments: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true
        },
        {
          field: 'name',
          label: 'Name'
        },
        {
          field: 'type',
          label: 'Type'
        },
        {
          field: 'id_parent',
          label: 'Parent ID'
        },
        {
          field: 'created',
          label: 'Created'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['organization', 'organizations', 'requestOrganizations', 'showLoader', 'usersPagination']),
    filteredOrganizations() {
      if (!this.$route.params.id) {
        return this.organizations.filter(
          organization =>
            organization.name
              .toString()
              .toLowerCase()
              .includes(this.parentOrgName.toLowerCase()) && organization.id !== 1
        )
      } else {
        let organizationExluded = this.organization.id // Id of the the organization being edited, this prevents its appearing on autocomplete
        return this.organizations.filter(
          organization =>
            organization.name
              .toString()
              .toLowerCase()
              .includes(this.parentOrgName.toLowerCase()) &&
            organization.id !== 1 &&
            organization.id !== organizationExluded
        )
      }
    }
  },
  mounted() {
    this.getOrganizations({ page: 1, size: 0 }).then(() => {
      this.allOrganizations = this.organizations.map(organization => {
        return { ...organization }
      })
    })
    this.fetchCountries()
      .then(countries => {
        this.countries = countries
      })
      .catch(error => {
        console.error('Error fetching countries:', error)
      })
    if (this.$route.params.id) {
      this.organizationId = this.$route.params.id
      this.getOrganizationById(this.$route.params.id, true, true, true).then(() => {
        this.getUsersByOrganization = this.organization.users.map(user => {
          return { ...user }
        })
        this.getLicenses = this.organization.licenses.map(license => {
          return { ...license }
        })
        this.getPricingRules = this.organization.pricingRules.map(pricingRules => {
          return { ...pricingRules }
        })
        this.getChildrenOrg = this.organization.children.map(children => {
          return { ...children }
        })
        this.getSegments = this.organization.segments.map(segments => {
          return { ...segments }
        })
        this.organizationName = this.organization.name
        this.organizationCountry = this.organization.country || null
        this.organizationTier = this.organization.tier || null
        this.selectedParent = this.organization.hasOwnProperty('parent') ? { ...this.organization.parent } : null
        this.parentOrgName = this.organization.hasOwnProperty('parent') ? this.organization.parent.name : ''
      })
    }
  },
  methods: {
    ...mapActions(['getOrganizations', 'createOrganization', 'getOrganizationById', 'fetchCountries']),
    onOrganizationCreate() {
      let organization
      if (this.$route.params.hasOwnProperty('id')) {
        organization = {
          idOrganization: this.$route.params.id,
          name: this.organizationName,
          tier: this.organizationTier,
          country: this.organizationCountry,
          parentId: this.selectedParent && this.parentOrgName.length > 0 ? this.selectedParent.id : null,
          file: this.files
        }
      } else {
        organization = {
          name: this.organizationName,
          tier: this.organizationTier,
          country: this.organizationCountry,
          parentId: this.selectedParent && this.parentOrgName.length > 0 ? this.selectedParent.id : null,
          file: this.files
        }
      }
      this.validateBeforeSubmit([this.createOrganization], { organization, isEdition: this.$route.params.hasOwnProperty('id') })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
