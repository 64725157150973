var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal-template",
    [
      _c("template", { slot: "title" }, [_c("h1", [_vm._v("DMP")])]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            attrs: { id: "dmpForm", novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("b-field", { attrs: { label: "Generales" } }),
            _c(
              "b-field",
              { attrs: { position: "is-right", grouped: "" } },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: {
                      "true-value": 1,
                      "false-value": 0,
                      type: "is-accent",
                      size: "is-small"
                    },
                    model: {
                      value: _vm.appEnabled,
                      callback: function($$v) {
                        _vm.appEnabled = $$v
                      },
                      expression: "appEnabled"
                    }
                  },
                  [_vm._v("Habilitada")]
                )
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  "custom-class": _vm.formatedClasses,
                  label: "Tipo de Cliente"
                }
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      size: "is-small",
                      placeholder: "Selecciona un tipo de cliente",
                      required: "",
                      expanded: "",
                      icon: "user"
                    },
                    model: {
                      value: _vm.selectedClientType,
                      callback: function($$v) {
                        _vm.selectedClientType = $$v
                      },
                      expression: "selectedClientType"
                    }
                  },
                  _vm._l(_vm.clientTypes, function(clientType) {
                    return _c(
                      "option",
                      {
                        key: clientType.id,
                        domProps: { value: clientType.id }
                      },
                      [_vm._v(_vm._s(clientType.type))]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { "custom-class": _vm.formatedClasses, label: "URL" } },
              [
                _c("b-input", {
                  attrs: {
                    name: "url",
                    size: "is-small",
                    placeholder: "Dirección URL para DMP"
                  },
                  model: {
                    value: _vm.appURL,
                    callback: function($$v) {
                      _vm.appURL = $$v
                    },
                    expression: "appURL"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  "custom-class": _vm.formatedClasses,
                  label: "Lenguaje"
                }
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      size: "is-small",
                      placeholder: "Selecciona un lenguaje",
                      required: "",
                      expanded: "",
                      icon: "language"
                    },
                    model: {
                      value: _vm.selectedLanguage,
                      callback: function($$v) {
                        _vm.selectedLanguage = $$v
                      },
                      expression: "selectedLanguage"
                    }
                  },
                  _vm._l(_vm.languages, function(language) {
                    return _c(
                      "option",
                      {
                        key: language.id,
                        domProps: { value: language.shortHand }
                      },
                      [_vm._v(_vm._s(language.name))]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: { "custom-class": _vm.formatedClasses, label: "Moneda" }
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      size: "is-small",
                      placeholder: "Selecciona una moneda",
                      required: "",
                      expanded: "",
                      icon: "dollar-sign"
                    },
                    model: {
                      value: _vm.selectedCurrency,
                      callback: function($$v) {
                        _vm.selectedCurrency = $$v
                      },
                      expression: "selectedCurrency"
                    }
                  },
                  _vm._l(_vm.currencies, function(currency) {
                    return _c(
                      "option",
                      { key: currency.id, domProps: { value: currency.id } },
                      [_vm._v(_vm._s(currency.name))]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            !this.app.hasOwnProperty("data")
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      display: "block",
                      "margin-top": "2%",
                      "margin-bottom": "2%"
                    }
                  },
                  [
                    _c("b-field", {
                      attrs: {
                        "custom-class": _vm.formatedClasses,
                        label: "Parent pricing rules"
                      }
                    }),
                    _c(
                      "b-field",
                      { attrs: { position: "is-left", grouped: "" } },
                      [
                        _c(
                          "b-checkbox",
                          {
                            attrs: {
                              "true-value": 1,
                              "false-value": 0,
                              type: "is-accent",
                              size: "is-small"
                            },
                            model: {
                              value: _vm.applyParentPrice,
                              callback: function($$v) {
                                _vm.applyParentPrice = $$v
                              },
                              expression: "applyParentPrice"
                            }
                          },
                          [_vm._v("Apply parent pricing rules")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-field",
              {
                attrs: {
                  "custom-class": _vm.formatedClasses,
                  type: _vm.errors.has("radio_shared_data") ? "is-danger" : "",
                  message: _vm.errors.first("radio_shared_data"),
                  label: "Shared audiences"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c(
                      "b-radio",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          "native-value": true,
                          name: "radio_shared_data",
                          size: "is-small"
                        },
                        model: {
                          value: _vm.shareData,
                          callback: function($$v) {
                            _vm.shareData = $$v
                          },
                          expression: "shareData"
                        }
                      },
                      [_vm._v("Habilitada")]
                    ),
                    _c(
                      "b-radio",
                      {
                        attrs: {
                          "native-value": false,
                          name: "radio_shared_data",
                          size: "is-small"
                        },
                        model: {
                          value: _vm.shareData,
                          callback: function($$v) {
                            _vm.shareData = $$v
                          },
                          expression: "shareData"
                        }
                      },
                      [_vm._v("Deshabilitada")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("b-field", { attrs: { label: "Plugins" } }),
            _c(
              "b-field",
              { attrs: { "custom-class": _vm.formatedClasses } },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      grouped: "",
                      "group-multiline": "",
                      position: "is-left"
                    }
                  },
                  [
                    _c(
                      "b-checkbox-button",
                      {
                        attrs: {
                          "native-value": true,
                          size: "is-small",
                          type: "is-success"
                        },
                        model: {
                          value: _vm.dmpTools.mockingbird,
                          callback: function($$v) {
                            _vm.$set(_vm.dmpTools, "mockingbird", $$v)
                          },
                          expression: "dmpTools.mockingbird"
                        }
                      },
                      [
                        _c("b-icon", { attrs: { icon: "crow" } }),
                        _c("span", [_vm._v("Mocking Bird")])
                      ],
                      1
                    ),
                    _c(
                      "b-checkbox-button",
                      {
                        attrs: {
                          "native-value": true,
                          size: "is-small",
                          type: "is-success"
                        },
                        model: {
                          value: _vm.dmpTools.earningsEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.dmpTools, "earningsEnabled", $$v)
                          },
                          expression: "dmpTools.earningsEnabled"
                        }
                      },
                      [
                        _c("b-icon", { attrs: { icon: "dollar-sign" } }),
                        _c("span", [_vm._v("Earnings")])
                      ],
                      1
                    ),
                    _c(
                      "b-checkbox-button",
                      {
                        attrs: {
                          "native-value": true,
                          size: "is-small",
                          type: "is-success"
                        },
                        model: {
                          value: _vm.dmpTools.brandedData,
                          callback: function($$v) {
                            _vm.$set(_vm.dmpTools, "brandedData", $$v)
                          },
                          expression: "dmpTools.brandedData"
                        }
                      },
                      [
                        _c("b-icon", { attrs: { icon: "certificate" } }),
                        _c("span", [_vm._v("Branded Data")])
                      ],
                      1
                    ),
                    _c(
                      "b-checkbox-button",
                      {
                        attrs: {
                          "native-value": true,
                          size: "is-small",
                          type: "is-success"
                        },
                        model: {
                          value: _vm.dmpTools.allowAllDataQuery,
                          callback: function($$v) {
                            _vm.$set(_vm.dmpTools, "allowAllDataQuery", $$v)
                          },
                          expression: "dmpTools.allowAllDataQuery"
                        }
                      },
                      [
                        _c("b-icon", { attrs: { icon: "globe" } }),
                        _c("span", [_vm._v("All Data Query")])
                      ],
                      1
                    ),
                    _c(
                      "b-checkbox-button",
                      {
                        attrs: {
                          "native-value": true,
                          size: "is-small",
                          type: "is-success"
                        },
                        model: {
                          value: _vm.dmpTools.calculatorEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.dmpTools, "calculatorEnabled", $$v)
                          },
                          expression: "dmpTools.calculatorEnabled"
                        }
                      },
                      [
                        _c("b-icon", { attrs: { icon: "calculator" } }),
                        _c("span", [_vm._v("Calculadora Planner")])
                      ],
                      1
                    ),
                    _c(
                      "b-checkbox-button",
                      {
                        attrs: {
                          "native-value": true,
                          size: "is-small",
                          type: "is-success"
                        },
                        model: {
                          value: _vm.dmpTools.adServerIntegration,
                          callback: function($$v) {
                            _vm.$set(_vm.dmpTools, "adServerIntegration", $$v)
                          },
                          expression: "dmpTools.adServerIntegration"
                        }
                      },
                      [
                        _c("b-icon", { attrs: { icon: "server" } }),
                        _c("span", [_vm._v("Ad Servers Integration")])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("b-field", { attrs: { label: "Presentación" } }),
            _c(
              "b-field",
              {
                attrs: { "custom-class": _vm.formatedClasses, label: "Theme" }
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      size: "is-small",
                      placeholder: "Seleccione un theme",
                      required: "",
                      expanded: "",
                      icon: "palette"
                    },
                    model: {
                      value: _vm.selectedTheme,
                      callback: function($$v) {
                        _vm.selectedTheme = $$v
                      },
                      expression: "selectedTheme"
                    }
                  },
                  _vm._l(_vm.organizationAppThemes, function(theme) {
                    return _c(
                      "option",
                      { key: theme.id, domProps: { value: theme.id } },
                      [_vm._v(_vm._s(_vm._f("appNames")(theme.name)))]
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c(
              "b-field",
              {
                staticClass: "file is-info",
                class: { "has-name": !!_vm.image },
                attrs: { position: "is-left" }
              },
              [
                _c(
                  "b-upload",
                  {
                    staticClass: "file-label",
                    attrs: { name: "file", expanded: "" },
                    model: {
                      value: _vm.image,
                      callback: function($$v) {
                        _vm.image = $$v
                      },
                      expression: "image"
                    }
                  },
                  [
                    _c(
                      "a",
                      { staticClass: "file-cta button is-info is-small" },
                      [
                        _c("b-icon", {
                          staticClass: "file-icon",
                          attrs: { icon: "upload" }
                        }),
                        _c("span", { staticClass: "file-label" }, [
                          _vm._v("Agregar Imagen de Header")
                        ])
                      ],
                      1
                    ),
                    _vm.image
                      ? _c("span", { staticClass: "file-name is-size-7" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.image.name) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("template", { slot: "buttons" }, [
        _c(
          "button",
          { staticClass: "button", on: { click: _vm.formModalCancel } },
          [_vm._v("Cancelar")]
        ),
        _c(
          "button",
          {
            staticClass: "button is-accent",
            class: { "is-loading": _vm.showLoader },
            on: { click: _vm.onDMPSetConfig }
          },
          [_vm._v("Aceptar")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }