<template>
  <fragment>
    <div class="column is-4">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">{{ getAppName() }}</p>
          <a class="card-header-icon" aria-label="more options">
            <span class="icon has-text-accent">
              <i class="fas fa-laptop-code" aria-hidden="true"></i>
            </span>
          </a>
        </header>
        <div class="card-content">
          <div class="content">
            <p class="has-text-right">
              <span class="has-text-weight-bold">Status:&nbsp;</span>
              <b-tag :type="app.enabled ? 'is-success' : 'is-danger'">{{ statusApp }}</b-tag>
            </p>
            <!-- <p class="has-text-justified">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius placeat iure tempore consequatur eum animi quibusdam, pariatur,
              dolor natus saepe ducimus recusandae aperiam. Optio eaque in, commodi hic officia eligendi.
            </p> -->
          </div>
        </div>
        <footer class="card-footer">
          <p v-if="!app.enabled" class="card-footer-item">
            <a ref="activator" :disabled="!isConfigured" class="button is-white" @click="invokeProductActivation(0, $event)">
              <span class="icon has-text-success">
                <i class="fas fa-check"></i>
              </span>
              <span>Activar</span>
            </a>
          </p>
          <p v-else class="card-footer-item">
            <a ref="activator" class="button is-white" @click="invokeProductActivation(1, $event)">
              <span class="icon has-text-danger">
                <i class="fas fa-ban"></i>
              </span>
              <span>Desactivar</span>
            </a>
          </p>
          <p class="card-footer-item">
            <a ref="config" :disabled="isNotConfigurable" class="button is-white" @click="tryConfigurate">
              <span class="icon has-text-info">
                <i class="fas fa-cogs"></i>
              </span>
              <span>Configurar</span>
            </a>
          </p>
        </footer>
      </div>
      <b-modal :active.sync="modalActive" :canCancel="['x', 'outside']" has-modal-card>
        <app-modal-render :app="app"></app-modal-render>
      </b-modal>
    </div>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OrganizationAppModalRender from './OrganizationAppModalRender'

export default {
  name: 'organizationAppItem',
  components: {
    'app-modal-render': OrganizationAppModalRender
  },
  props: {
    app: {
      type: Object,
      default: () => {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modalActive: false
    }
  },
  computed: {
    ...mapGetters(['organization', 'requestOrganizations']),
    isNotConfigurable() {
      return this.app.id === 2 || this.app.id === 4 || this.app.id === 10
    },
    isConfigured() {
      return this.app.hasOwnProperty('data') || this.app.id === 2 || this.app.id === 9 || this.app.id === 4 || this.app.id === 10
    },
    statusApp() {
      return this.app.enabled ? 'Activado' : 'Desactivado'
    }
  },
  mounted() {
    console.log('🚀 ~ file: OrganizationAppItem.vue:99 ~ mounted ~ this.show:', this.show)
  },
  methods: {
    ...mapActions(['setActivationProduct']),
    tryConfigurate() {
      if (!this.isNotConfigurable) {
        this.modalActive = true
      }
    },
    getAppName() {
      const appDictionary = {
        10: 'Origin'
      }
      return appDictionary[this.app.id] || this.app.name
    },
    invokeProductActivation(operation, event) {
      let product = {
        organizationId: this.organization.id,
        productId: this.app.id,
        enabled: this.app.enabled,
        operation
      }
      if (!this.$refs.activator.getAttribute('disabled')) {
        this.setActivationProduct(product).then(() => {
          if (product.operation === 0) {
            this.$buefy.snackbar.open(`Aplicación Activada`)
          } else {
            this.$buefy.snackbar.open({ message: `Aplicación Desactivada`, type: 'is-warning' })
          }
        })
      } else {
        this.$buefy.snackbar.open({ message: `Debes configurar previamente la aplicación para activarla`, type: 'is-info' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
