var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-input", {
    attrs: {
      value: _vm.value,
      type: "number",
      min: "1",
      size: "is-small",
      placeholder: "Price",
      step: "0.01"
    },
    nativeOn: {
      input: function($event) {
        return _vm.handleInput($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }