var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-organization-template",
    [
      _c("template", { slot: "title" }, [_vm._v("\n    Seats\n  ")]),
      _c("template", { slot: "message" }, [
        _c("div", { staticClass: "columns is-centered" }, [
          _c(
            "div",
            { staticClass: "column is-two-thirds" },
            [
              _vm.auth_seats_ok.length !== 0
                ? _c(
                    "b-message",
                    { attrs: { type: "is-success", "has-icon": "" } },
                    [
                      _c("p", { staticClass: "is-size-5" }, [
                        _vm._v(
                          "Los siguientes seats han sido autorizados exitosamente:"
                        )
                      ]),
                      _c("p", { staticClass: "is-size-7" }, [
                        _c(
                          "ul",
                          { attrs: { id: "example-1" } },
                          _vm._l(_vm.auth_seats_ok, function(item) {
                            return _c("li", { key: item.id }, [
                              _vm._v(
                                "\n                  id: " +
                                  _vm._s(item.id) +
                                  " - name:  " +
                                  _vm._s(item.name) +
                                  "\n                "
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.auth_seats_error.length !== 0
                ? _c(
                    "b-message",
                    { attrs: { type: "is-warning", "has-icon": "" } },
                    [
                      _c("p", { staticClass: "is-size-5" }, [
                        _vm._v(
                          "Los siguientes seats no han podido ser autorizados:"
                        )
                      ]),
                      _c("p", { staticClass: "is-size-7" }, [
                        _c(
                          "ul",
                          { attrs: { id: "example-1" } },
                          _vm._l(_vm.auth_seats_error, function(item) {
                            return _c("li", { key: item.id }, [
                              _vm._v(
                                "\n                 Seat Id: " +
                                  _vm._s(item.id) +
                                  " - error: " +
                                  _vm._s(item.error) +
                                  "\n                "
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _c("hr")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }