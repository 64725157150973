<template>
  <app-modal-template>
    <template slot="title">
      <h1>IDx UI</h1>
    </template>
    <template slot="content">
      <form id="idxForm" novalidate @submit.prevent>
        <b-field label="Requeridas"></b-field>
        <b-field grouped>
          <b-field :custom-class="formatedClasses" label="IDx">
            <b-input :readonly="true" v-model="mask" name="mask" size="is-small" placeholder=""></b-input>
          </b-field>
          <b-checkbox :true-value="true" :false-value="false" v-model="appEnabled" type="is-accent" size="is-small">
            IDx habilitado
          </b-checkbox>
        </b-field>
        <b-field grouped>
          <b-field :custom-class="formatedClasses" label="Nombre">
            <b-input v-model="name" name="name" size="is-small" placeholder="Nombre"></b-input>
          </b-field>
          <b-field :custom-class="formatedClasses" label="Tipo de Proveedor">
            <b-select v-model="selectedProviderType" size="is-small" placeholder="Selecciona un tipo de proveedor"
                      required
                      expanded icon="user">
              <option v-for="providerType in providerTypes" :key="providerType.id" :value="providerType.id">
                {{ providerType.type }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-field label="Opcionales"></b-field>
        <b-field grouped>
          <b-field :custom-class="formatedClasses" label="Nombre Corto">
            <b-input v-model="short_name" name="shortName" size="is-small" placeholder="Nombre corto"></b-input>
          </b-field>
          <b-field :custom-class="formatedClasses" label="ID Dashboard">
            <b-input v-model="dashboard_id" name="dashboardId" size="is-small" placeholder="ID Dashboard"></b-input>
          </b-field>
        </b-field>
        <b-field :custom-class="formatedClasses" label="Clave publica">
          <b-input v-model="public_key" name="publicKey" size="is-small" placeholder="Clave publica" type="textarea"></b-input>
        </b-field>
        <b-field label="Retargetly Pixel drop"></b-field>
        <b-field grouped>
          <b-checkbox :true-value="1" :false-value="0" v-model="dropPixelEnabled" type="is-accent" size="is-small">
            Pixel Drop Habilitado
          </b-checkbox>
        </b-field>
        <b-field grouped>
          <b-field :custom-class="formatedClasses" label="Source">
            <b-input v-model="id_source" name="idSource" size="is-small" placeholder="ID source"></b-input>
          </b-field>
          <b-field :custom-class="formatedClasses" label="Url">
            <b-input v-model="url" name="url" size="is-small" placeholder="Url"></b-input>
          </b-field>
        </b-field>
        <b-field label="Pixel">
          <div id="pixelArea" title="Copiar al portapapeles" @click="copyTagToClipboard">
            <b-input v-model="pixel" type="textarea" disabled/>
          </div>
        </b-field>
      </form>
    </template>
    <template slot="buttons">
      <button class="button" @click="formModalCancel">Cancelar</button>
      <button :class="{ 'is-loading': showLoader }" class="button is-accent" @click="onIDxSetConfig">Aceptar</button>
    </template>
  </app-modal-template>
</template>

<script>
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'
import { mapGetters, mapActions } from 'vuex'
import { formMixin } from '@/mixins/formMixin'

export default {
  name: 'idxForm',
  components: {
    'app-modal-template': ModalTemplate
  },
  mixins: [formMixin],
  props: {
    id: {
      type: String,
      default: ''
    },
    app: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      customClasses: ['has-text-grey-dark', 'is-size-7', 'has-text-weight-light'],
      providerTypes: [{ id: 'pub', type: 'Publisher' }, { id: 'dmp', type: 'DMP' }, { id: 'dsp', type: 'DSP' }, { id: 'ssp', type: 'SSP' }],
      appEnabled: false,
      selectedProviderType: 'pub',
      providerId: null,
      mask: '',
      name: '',
      short_name: '',
      public_key: '',
      dashboard_id: '',
      providerData: {},
      idxMappingData: {},
      dropPixelEnabled: 0,
      url: null,
      id_source: null,
      pixel: ''
    }
  },
  computed: {
    ...mapGetters(['organization', 'requestSuccess', 'serverError', 'showLoader', 'requestSucces']),
    formatedClasses() {
      return this.customClasses.join(' ')
    }
  },
  watch: {
    selectedProviderType: function(newSelected, oldQuestion) {
      console.log('TCL: newSelected', newSelected)
    }
  },
  mounted() {
    // Detects and set data for edition
    if (this.app.hasOwnProperty('providerData')) {
      this.prepareDataForEdition()
    }
  },
  methods: {
    ...mapActions(['setAppConfiguration']),
    copyTagToClipboard() {
      if (!this.providerId) {
        this.$buefy.toast.open({
          message: `Debe crear el registro para copiar el tag.`,
          type: 'is-danger'
        })
        return
      }
      let r = document.createRange()
      r.selectNode(document.getElementById('pixelArea'))
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(r)
      try {
        const successful = document.execCommand('copy')
        if (successful) {
          this.$buefy.toast.open({
            message: `IDx tag copiado en el portapapeles.`,
            type: 'is-success'
          })
        } else {
          this.$buefy.toast.open({
            message: `No se pudo copiar el tag en el portapapeles.`,
            type: 'is-danger'
          })
        }
      } catch (err) {
        console.error(err)
      }

      window.getSelection().removeAllRanges()
    },
    onIDxSetConfig() {
      this.providerData = {
        id: this.providerId,
        name: this.name,
        enabled: !!this.appEnabled,
        type: this.selectedProviderType,
        short_name: this.short_name,
        public_key: this.public_key,
        dashboard_id: this.dashboard_id,
        pixel: this.pixel
      }
      this.idxMappingData = {
        enabled: this.dropPixelEnabled ? 1 : 0,
        id_source: this.id_source,
        url: this.url
      }

      console.log('onIDxSetConfig -> this.providerData', this.providerData)
      console.log('onIDxSetConfig -> this.idxMappingData', this.idxMappingData)

      this.validateBeforeSubmit([this.setAppConfiguration], {
        appId: this.app.id,
        organization: {
          id: this.$route.params.id,
          providerData: this.providerData,
          idxMappingData: this.idxMappingData,
          mask: this.mask
        },
        isEdition:
          this.app.hasOwnProperty('providerData') &&
          this.app.providerData &&
          (typeof this.app.providerData.mask !== 'undefined' && this.app.providerData.mask !== '')
      })
    },
    prepareDataForEdition() {
      const providerConfiguration = { ...this.app.providerData }
      console.log('prepareDataForEdition -> providerConfiguration', providerConfiguration)
      this.providerId = providerConfiguration.id
      this.mask = providerConfiguration.mask
      this.name = providerConfiguration.name
      this.appEnabled = providerConfiguration.enabled
      this.selectedProviderType = providerConfiguration.type
      this.public_key = providerConfiguration.public_key
      this.dashboard_id = providerConfiguration.dashboard_id
      this.short_name = providerConfiguration.short_name
      this.pixel = providerConfiguration.pixel

      const idxMappingConfiguration = { ...this.app.idxMappingData }
      console.log('prepareDataForEdition -> idxMappingConfiguration', idxMappingConfiguration)
      this.dropPixelEnabled = idxMappingConfiguration.enabled
      this.url = idxMappingConfiguration.url
      this.id_source = idxMappingConfiguration.id_source
    }
  }
}
</script>

<style scoped>
</style>
