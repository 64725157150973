var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-organization-template",
    [
      _vm.isCreation
        ? _c("template", { slot: "title" }, [
            _vm._v(" Crear Nueva Organización")
          ])
        : _c("template", { slot: "title" }, [_vm._v(" Editar Organización")]),
      _c("template", { slot: "message" }, [
        _c("div", { staticClass: "columns is-centered" }, [
          _c(
            "div",
            { staticClass: "column is-two-thirds" },
            [
              _c(
                "b-message",
                { attrs: { type: "is-success", "has-icon": "" } },
                [
                  _vm.isCreation
                    ? _c("p", { staticClass: "is-size-4" }, [
                        _vm._v("\n            La organización "),
                        _c("strong", [_vm._v(_vm._s(_vm.organization.name))]),
                        _vm._v(" ha sido creada con éxito!\n          ")
                      ])
                    : _c("p", { staticClass: "is-size-4" }, [
                        _vm._v("\n            La organización "),
                        _c("strong", [_vm._v(_vm._s(_vm.organization.name))]),
                        _vm._v(" fue editada con exito!\n          ")
                      ]),
                  _c("p", [
                    _vm._v("Presiona "),
                    _c("span", { staticClass: "tag is-small is-accent" }, [
                      _vm._v("Configurar Aplicaciones")
                    ]),
                    _vm._v(" Si deseas configurar y activarlas")
                  ]),
                  _vm.isUserAdmin
                    ? _c("p", [
                        _vm._v(
                          "\n            Asigne los precios de la cuenta\n            "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button is-accent is-small",
                            on: {
                              click: function($event) {
                                return _vm.goToPriceCreation(_vm.id)
                              }
                            }
                          },
                          [_c("span", [_vm._v("Aqui")])]
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ]),
        _c("hr")
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _c("b-field", { attrs: { grouped: "", position: "is-right" } }, [
            _c(
              "p",
              { staticClass: "control" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button",
                    attrs: {
                      to: {
                        name: "organizationEdit",
                        params: { id: "" + _vm.organization.id }
                      },
                      tag: "button"
                    }
                  },
                  [_vm._v("\n          Volver\n        ")]
                )
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "control" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button is-accent",
                    attrs: { to: { name: "organizationApps" }, tag: "button" }
                  },
                  [_vm._v(" Configurar Aplicaciones ")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }