var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "keep-alive",
        [
          _c(_vm.selectedComponent, {
            tag: "component",
            attrs: { app: _vm.app },
            on: {
              "on-form-success": _vm.showSuccessMessages,
              "on-form-error": _vm.showErrorMessages,
              "on-form-processing": _vm.clearMessages,
              "on-form-cancel": _vm.closeModal
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }