var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column", attrs: { id: "main-container" } }, [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "card-content" },
        [
          _c(
            "b-table",
            {
              attrs: {
                loading: _vm.loading,
                data: _vm.rule ? _vm.rule : [],
                striped: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c(
                        "b-table-column",
                        { attrs: { field: "id", label: "ID", width: "40" } },
                        [_vm._v(_vm._s(props.row.idRule))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "client",
                            label: "Client",
                            centered: ""
                          }
                        },
                        [_vm._v(_vm._s(props.row.client.name))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "platform",
                            label: "Platform",
                            centered: ""
                          }
                        },
                        [_vm._v(_vm._s(props.row.platform.name))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "cluster",
                            label: "Cluster",
                            centered: ""
                          }
                        },
                        [_vm._v(_vm._s(props.row.cluster.name))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "segType",
                            label: "Segment Type",
                            centered: ""
                          }
                        },
                        [_vm._v(_vm._s(props.row.segmentType.name))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "price",
                            label: "Price",
                            centered: ""
                          }
                        },
                        [_vm._v(_vm._s(props.row.price))]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("template", { slot: "empty" }, [_vm._v("No hay resultados")])],
            2
          ),
          !_vm.editing
            ? [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "b-field",
                      { attrs: { grouped: "", position: "is-left" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "button is-accent",
                            class: { "is-loading": _vm.loading },
                            on: { click: _vm.handleEdit }
                          },
                          [_vm._v("Edit")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "card-content" },
                      [
                        _c(
                          "b-field",
                          { attrs: { label: "Price" } },
                          [
                            _c("b-numberinput", {
                              attrs: {
                                min: "0",
                                type: "is-primary",
                                "controls-position": "compact",
                                "controls-rounded": "",
                                step: "0.01"
                              },
                              model: {
                                value: _vm.price,
                                callback: function($$v) {
                                  _vm.price = $$v
                                },
                                expression: "price"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ],
          [
            _c("hr"),
            _c(
              "div",
              { staticClass: "card-content" },
              [
                _c(
                  "b-field",
                  { attrs: { grouped: "", position: "is-right" } },
                  [
                    _c(
                      "p",
                      { staticClass: "control" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "button",
                            attrs: { to: { name: "pricing" } }
                          },
                          [_c("span", [_vm._v("Cancelar")])]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "button is-accent",
                            class: { "is-loading": _vm.loading },
                            attrs: { disabled: !this.editing },
                            on: { click: _vm.handleConfirm }
                          },
                          [_c("span", [_vm._v("Confirm")])]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "header",
      { staticClass: "card-header is-flex is-flex--space-between" },
      [
        _c("div", [
          _c(
            "p",
            { staticClass: "card-header-title is-size-5 has-text-grey" },
            [_vm._v("Edit Overview")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }