<template>
  <app-organization-template>
    <template slot="title">
      Seats
    </template>
    <template slot="message">
      <div class="columns is-centered">
        <div class="column is-two-thirds">
          <b-message v-if="auth_seats_ok.length!==0" type="is-success" has-icon>
            <p class="is-size-5">Los siguientes seats han sido autorizados exitosamente:</p>
            <p class="is-size-7">
                <ul id="example-1">
                  <li v-for="item in auth_seats_ok" v-bind:key="item.id">
                    id: {{ item.id }} - name:  {{ item.name }}
                  </li>
                </ul>
            </p>
          </b-message>
          <b-message v-if="auth_seats_error.length!==0" type="is-warning" has-icon >
            <p class="is-size-5">Los siguientes seats no han podido ser autorizados:</p>
             <p class="is-size-7">
                <ul id="example-1">
                  <li v-for="item in auth_seats_error" v-bind:key="item.id">
                   Seat Id: {{ item.id }} - error: {{ item.error }}
                  </li>
                </ul>
            </p>
          </b-message>
        </div>
      </div>
      <hr>
    </template>

  </app-organization-template>
</template>

<script>
import {mapGetters} from 'vuex'
import CommonTemplate from '@/views/templates/CommonTemplate'

export default {
  name: 'seatConfirmation',
  components: {
    'app-organization-template': CommonTemplate
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'seats',
      'auth_seats_ok',
      'auth_seats_error'
    ])
  },
  created () {

  }
}
</script>

<style lang="scss" scoped>

</style>
