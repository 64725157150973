<template>
  <section>
    <app-organization-template>
      <template slot="title">
        {{ setOrganizationOperationTitle() }}
        <b-loading :is-full-page="true" :active.sync="requestOrganizations" :can-cancel="true"></b-loading>
      </template>
      <template slot="content">
        <app-organization-form
          @on-form-success="goToConfirmation"
          @on-form-error="showErrorMessage"
          @on-form-processing="clearMessages"
        ></app-organization-form>
      </template>
    </app-organization-template>
  </section>
</template>

<script>
import CommonTemplate from '@/views/templates/CommonTemplate'
import OrganizationForm from './OrganizationForm'
import { mapGetters } from 'vuex'

export default {
  name: 'organizationCreate',
  components: {
    'app-organization-template': CommonTemplate,
    'app-organization-form': OrganizationForm
  },
  data() {
    return {
      error: { hasError: false, invalidFormError: false },
      success: { hasSuccess: false },
      title: ['Crear Nueva Organización', 'Editar Organización']
    }
  },
  computed: {
    ...mapGetters(['organization', 'requestOrganizations', 'tokenValid'])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  methods: {
    clearMessages({ ...error }, success = { hasSuccess: false }) {
      this.error = error
      this.success = { ...success }
    },
    goToConfirmation({ ...success }) {
      console.log('this.$route.params.id', this.$route.params.id)
      if (this.$route.params.id) {
        this.$router.push({ name: 'organizationConfirmation', props: true, params: { id: this.organization.id.toString(), isCreation: false } })
      } else {
        this.$router.push({ name: 'organizationConfirmation', params: { id: this.organization.id.toString() } })
      }
    },
    showErrorMessage({ ...error }) {
      this.error = error
      this.$buefy.snackbar.open({ message: `¡Ups algo salió mal!`, type: 'is-danger' })
    },
    setOrganizationOperationTitle() {
      if (this.$route.params.id) {
        return this.title[1]
      }
      return this.title[0]
    }
  }
}
</script>

<style scoped>
</style>
