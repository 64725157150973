<template>
<div id="main-container"  class="column">
        <div class="card">
            <header class="card-header is-flex is-flex--space-between">
            <div>
                <p class="card-header-title is-size-5 has-text-grey">Creación de reglas de precio</p>
            </div>
            </header>
        <div class="card-content">
          <div v-for="(rule, index) in rules" :key="rule.id">
            <form validate @submit.prevent >
            <div class="columns is-desktop">
            <div class="column">
              <cli-input  v-model="rule.clients" :condition="rule.selectedRule"></cli-input>
              <p class="error">
                {{rule.error.clients ? rule.error.clients : null}}
              </p>
            </div>
            <div class="column">
              <plat-input v-model="rule.platforms" ></plat-input>
              <p class="error">
              {{rule.error.platforms ? rule.error.platforms : null}}
              </p>
            </div>
            <div v-if="rule.selectedRule === 'firstparty'" class="column">
              <segment-input v-model="rule.segmentType" ></segment-input>
              <p class="error">
              {{rule.error.segmentType ? rule.error.segmentType : null}}
              </p>
            </div>
            <div v-else class="column">
              <cluster-input v-model="rule.clusters"></cluster-input>
              <p class="error">
              {{rule.error.clusters ? rule.error.clusters : null}}
              </p>
            </div>
            <div class="column">
              <rules-input v-model="rule.selectedRule"></rules-input>
            </div>
            <div  class="column is-2">
              <b-field label="Precio">
                  <b-numberinput v-model="rule.price" min="0" size="is-small" type="is-primary" controls-position="compact" controls-rounded step="0.01"></b-numberinput>
              </b-field>
            </div>
            <div :style="{ flex: '0', marginTop: '38px'}">
                <b-tooltip
                :label="`Borrar Regla`"
                position="is-top"
                type="is-primary"
                size="is-medium"
                animated
                rounded
                >
              <b-button :style="{border: 'none'}"  @click="handleRemove(index)">
                <b-icon pack="fas" icon="trash" size="is-big" type="is-primary" ></b-icon>
              </b-button>
              </b-tooltip>
            </div>
            </div>
            </form>
            <div class="columns">
              <div class="column is-full">
                <div v-if="rule.conflict" :style="{margin: '15px 0'}" >
                    <b-message type="is-warning" has-icon size="is-small" aria-close-label="Close message">
                      Se identifica la presencia de un conflicto por similitudes de valores de Regla.
                    </b-message>
                </div>
              </div>
            </div>
          </div>
            <div class="columns" >
              <div class="column is-1">
                <b-tooltip
                  :label="`Agregar una Regla`"
                  position="is-right"
                  type="is-accent"
                  size="is-small"
                  animated
                  rounded
                >
                  <b-button type="button" class="button is-accent" @click="handleAdd">
                    <strong>+</strong>
                    </b-button>
                </b-tooltip>
              </div>
            </div>
        </div>
        <hr />
        <div class="card-content">
        <b-field grouped position="is-right">
          <p class="control">
            <router-link :to="{name: 'pricing'}" class="button">
              <span>Cancelar</span>
            </router-link>
          </p>
          <div class="control">
            <div>
              <a :class="{'is-loading': loading}" class="button is-accent" @click="handleConfirm">
                <span>Confirmar</span>
              </a>
            </div>
          </div>
        </b-field>
        </div>
        <template>
            <div class="columns is-multiline">
              <div v-for="(rule, index) in rules" :key="rule.id" class="column is-one-third">
                <client-preview :index="index" :rule="rule" :selectedRule="rule.selectedRule" ></client-preview>
              </div>
            </div>
        </template>
        </div>
    </div>
</template>

<script>
import { uuid } from 'vue-uuid'

import CliInput from './customInputs/clientsInput'
import PlatInput from './customInputs/platformsInput'
import ClusterInput from './customInputs/clusterInput'
import SegmentInput from './customInputs/segmentTypesInput'
import InputRules from '../../common/widgets/rule-types/InputRules'

import ClientPreview from './ClientPreview'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    'cli-input': CliInput,
    'plat-input': PlatInput,
    'cluster-input': ClusterInput,
    'segment-input': SegmentInput,
    'rules-input': InputRules,
    'client-preview': ClientPreview
  },
  data() {
    return {
      loading: false,
      preview: false,
      confirmed: false,
      rules: [
        {
          id: uuid.v1(),
          conflict: false,
          selectedRule: 'firstparty',
          error: {},
          clients: [],
          platforms: [],
          clusters: [],
          segmentType: [],
          price: 0
        }
      ],
      allOrganizations: []
    }
  },
  computed: {
    ...mapGetters(['clients', 'platforms', 'clusters', 'segmentType', 'organization', 'tokenValid']),
    hasConflicts() {
      return this.rules.some(e => e.conflict)
    },
    hasValues() {
      return this.rules.every(rule => rule.clients.length && rule.platforms.length && (rule.segmentType.length || rule.clusters.length))
    }
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  created() {
    this.getClients()
    this.getPlatforms()
    this.getClusters()
    this.getSegmentTypes()
  },
  mounted() {
    if (this.$route.params.id) {
      this.getOrganizationById(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions(['getClients', 'getPlatforms', 'getClusters', 'getSegmentTypes', 'postRules', 'getOrganizationById']),
    handleConfirm() {
      this.loading = true
      this.executeInputsValidation()
      this.executeRulesValidation()

      if (this.hasConflicts || !this.hasValues) {
        this.loading = false
        return this.$buefy.toast.open({
          message: `Confictos presentes!`,
          type: 'is-danger'
        })
      } else {
        const parsedRules = this.rules.map(e => {
          switch (e.selectedRule) {
            case 'firstparty':
              return {
                clients: e.clients,
                platforms: e.platforms,
                segmentType: e.segmentType,
                clusters: [],
                type: e.selectedRule,
                price: e.price
              }
            case 'thirdparty':
              return {
                clients: e.clients,
                platforms: e.platforms,
                segmentType: [],
                clusters: e.clusters,
                type: e.selectedRule,
                price: e.price
              }
            default:
              return {}
          }
        })
        this.postRules(parsedRules)
          .then(response => {
            this.loading = false
            this.$buefy.toast.open({
              message: `Creacion de Rules OK!`,
              type: 'is-success'
            })
            this.$router.push('/pricing/list')
          })
          .catch(error => {
            this.loading = false
            return new Error(error.message)
          })
      }
    },
    handleAdd() {
      this.rules.push({
        id: uuid.v1(),
        selectedRule: 'firstparty',
        error: {},
        conflict: false,
        clients: [],
        platforms: [],
        clusters: [],
        segmentType: [],
        price: 0
      })
      return this.rules
    },
    handleCancel() {
      this.$router.push({ name: 'pricing' })
    },
    handleRemove(index) {
      if (this.rules.length === 1) return
      this.rules = this.rules.filter((e, i) => {
        return i !== index
      })
    },
    executeInputsValidation() {
      for (const rule in this.rules) {
        const ruleValues = this.rules[rule]
        ruleValues.error = {}
        for (const fields in ruleValues) {
          const vals = ruleValues[fields]
          if (vals.length === 0) {
            ruleValues.error[fields] = `You need to select ${fields}!`
          }
        }
      }
    },
    executeRulesValidation() {
      let simils = {}
      if (this.rules.length >= 1) {
        this.rules.forEach(rule => {
          rule.conflict = false
          this.rules.filter(e => e.id !== rule.id).forEach(filteredRule => {
            const { clients, platforms, clusters, segmentType } = filteredRule
            const parcedFilteredRule = {
              clients,
              platforms,
              clusters,
              segmentType,
              price: [filteredRule.price]
            }
            for (const key in parcedFilteredRule) {
              const values = parcedFilteredRule[key].concat(rule[key])
              if (new Set(values).size !== values.length) {
                simils[key] = true
              } else {
                simils[key] = false
              }
            }
            if (simils.clients && simils.platforms && (simils.clusters || simils.segmentType)) {
              rule.conflict = true
            }
          })
        })
      }
    },
    findDuplicates(array) {
      return array.length !== new Set(array).size
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  color: red;
  margin: 5px 0;
}
</style>
