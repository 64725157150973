var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card", style: { margin: "20px 20px" } }, [
    _c("div", { staticClass: "card-header" }, [
      _c("p", { staticClass: "card-header-title is-size-6" }, [
        _vm._v("\n        Preview #" + _vm._s(_vm.index + 1) + "\n        ")
      ]),
      _vm.rule.conflict
        ? _c(
            "div",
            [
              _c(
                "b-tooltip",
                {
                  style: { margin: "10px 10px" },
                  attrs: {
                    label: "Conflicto",
                    position: "is-top",
                    type: "is-primary",
                    size: "is-big",
                    animated: "",
                    rounded: ""
                  }
                },
                [
                  _c("b-icon", {
                    attrs: {
                      pack: "fas",
                      icon: "exclamation-triangle",
                      size: "is-big",
                      type: "is-warning"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "card-content" }, [
      _c("p", [
        _vm._v("Clients: "),
        _c("strong", [
          _vm._v(_vm._s(_vm.getClientGroupNames(_vm.rule.clients)))
        ])
      ]),
      _c("p", [
        _vm._v("Platforms: "),
        _c("strong", [_vm._v(_vm._s(_vm.getNames(_vm.rule, "platforms")))])
      ]),
      _vm.isFirstParty
        ? _c("div", [
            _c("p", [
              _vm._v("Segment Type: "),
              _c("strong", [
                _vm._v(_vm._s(_vm.getNames(_vm.rule, "segmentType")))
              ])
            ])
          ])
        : _c("div", [
            _c("p", [
              _vm._v("Clusters: "),
              _c("strong", [_vm._v(_vm._s(_vm.getNames(_vm.rule, "clusters")))])
            ])
          ]),
      _c("p", [
        _vm._v("Precio: "),
        _c("strong", [_vm._v("US$ " + _vm._s(_vm.rule.price))])
      ]),
      _c("p", [
        _vm._v("Type: "),
        _c("strong", [_vm._v(_vm._s(_vm.rule.selectedRule))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }