var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "app-organization-template",
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.setOrganizationOperationTitle()) +
                  "\n      "
              ),
              _c("b-loading", {
                attrs: {
                  "is-full-page": true,
                  active: _vm.requestOrganizations,
                  "can-cancel": true
                },
                on: {
                  "update:active": function($event) {
                    _vm.requestOrganizations = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "template",
            { slot: "content" },
            [
              _c("app-organization-form", {
                on: {
                  "on-form-success": _vm.goToConfirmation,
                  "on-form-error": _vm.showErrorMessage,
                  "on-form-processing": _vm.clearMessages
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }