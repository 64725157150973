<template>
  <div id="main-container" class="column">
    <div class="card">
      <header class="card-header is-flex is-flex--space-between">
          <div>
            <p class="card-header-title is-size-5 has-text-grey">Edit Overview</p>
          </div>
      </header>
    <div class="card-content">
        <b-table
          :loading="loading"
          :data="rule ? rule : []"
          striped
      >
        <template slot-scope="props">
          <b-table-column field="id" label="ID" width="40">{{ props.row.idRule }}</b-table-column>
          <b-table-column field="client" label="Client" centered>{{ props.row.client.name }}</b-table-column>
          <b-table-column field="platform" label="Platform" centered>{{ props.row.platform.name }}</b-table-column>
          <b-table-column field="cluster" label="Cluster" centered>{{ props.row.cluster.name }}</b-table-column>
          <b-table-column
            field="segType"
            label="Segment Type"
            centered
          >{{ props.row.segmentType.name}}</b-table-column>
          <b-table-column field="price" label="Price" centered>{{ props.row.price }}</b-table-column>
        </template>
        <template slot="empty">No hay resultados</template>
      </b-table>
      <template v-if="!editing">
        <div class="column">
          <b-field grouped position="is-left">
            <b-button :class="{'is-loading': loading}" class="button is-accent" @click="handleEdit">Edit</b-button>
          </b-field>
        </div>
      </template>
      <template v-else>
        <div class="columns">
          <div class="column">
            <div class="card-content">
              <b-field label="Price">
                <b-numberinput v-model="price" min="0" type="is-primary"  controls-position="compact" controls-rounded step="0.01">
                </b-numberinput>
              </b-field>
          </div>
          </div>
        </div>
      </template>
      <template>
      <hr />
      <div class="card-content">
        <b-field grouped position="is-right">
          <p class="control">
            <router-link :to="{name: 'pricing'}" class="button">
              <span>Cancelar</span>
            </router-link>
          </p>
          <div class="control">
              <b-button :class="{'is-loading': loading}" :disabled="!this.editing" class="button is-accent" @click="handleConfirm">
                <span>Confirm</span>
              </b-button>
          </div>
        </b-field>
        </div>
      </template>
    </div>
    </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      rule: null,
      price: null,
      editing: false
    }
  },
  computed: {
    ...mapGetters(['pricings', 'tokenValid'])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  created() {
    this.loading = true
    this.getPricingList().then(res => {
      console.log(res)
      this.rule = res.filter(e => e.idRule === parseInt(this.$router.history.current.params.id, 10))
      this.price = this.rule[0].price
      this.loading = false
    })
  },
  methods: {
    ...mapActions(['getPricingList', 'postRules']),
    handleEdit() {
      this.editing = true
    },
    handleCancel() {
      this.$router.push('/pricing/list')
    },
    handleConfirm() {
      this.loading = true
      let rul = { ...this.rule[0] }
      const parsed = [
        {
          clients: [rul.client.id],
          platforms: [rul.platform.id],
          clusters: [rul.cluster.id],
          segmentType: [rul.segmentType.id],
          type: [rul.type],
          price: this.price
        }
      ]
      this.postRules(parsed)
        .then(res => {
          this.loading = false
          this.$buefy.toast.open({
            message: `Edicion OK!`,
            type: 'is-success'
          })
          this.$router.push('/pricing/list')
        })
        .catch(error => {
          this.loading = false
          this.$buefy.toast.open({
            message: `Edicion Error!`,
            type: 'is-danger'
          })
          return new Error(error.message)
        })
    }
  }
}
</script>
