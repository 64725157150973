<template>
  <section>
    <app-organization-template>
      <template slot="title">
        <h2>Cargar Aplicaciones</h2>
        <h3 class="is-size-6">
          <span class="has-text-grey">Organización: </span> <span class="has-text-weight-bold">{{ organization.name }}</span>
        </h3>
      </template>
      <template slot="content">
        <div class="columns is-multiline">
          <app-organization-app-item
            v-for="(app, index) in filteredByPermissionProducts"
            :key="index"
            :app="app"
          ></app-organization-app-item>
        </div>
        <div v-show="requestOrganizationError" class="content has-text-danger has-text-centered">
          <p>
            <span class="icon is-large">
              <i class="far fa-frown fa-3x"></i>
            </span>
          </p>
          <p>{{ $t('errors.requestError') }}</p>
        </div>
      </template>
    </app-organization-template>
    <b-loading :is-full-page="true" :active.sync="requestOrganizations" :can-cancel="true"></b-loading>
  </section>
</template>

<script>
import CommonTemplate from '@/views/templates/CommonTemplate'
import OrganizationAppItem from './OrganizationAppItem'
import { mapGetters, mapActions } from 'vuex'
import utils from '@/utils/user-utils'

export default {
  name: 'organizationApps',
  components: {
    'app-organization-template': CommonTemplate,
    'app-organization-app-item': OrganizationAppItem
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['organization', 'organizationProducts', 'requestOrganizations', 'requestOrganizationError']),
    filteredByPermissionProducts() {
      return this.organizationProducts.filter((app) => {
        return app.name !== 'PDP' ? true : this.hasPermission({ feature: 'origin', action: 'view' }) //NOT A PERMISSION ANYMORE, REPLACE W/ THE PROPPER AUTH0 ONE
      })
    }
  },
  mounted() {
    this.getOrganizationById(this.id)
  },
  methods: {
    ...mapActions(['getOrganizationById']),
    hasPermission: ({ feature, action }) => {
      const permisions = utils.hasPermission(feature)
      return !!permisions && !!permisions[action]
    }
  }
}
</script>

<style scoped></style>
