<template>
  <b-input
    :value="value"
    type="number"
    min="1"
    size="is-small"
    placeholder="Price"
    step="0.01"
    @input.native="handleInput($event)"
  ></b-input>
</template>

<script>
export default {
  name: 'PriceInput',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    formatedClasses() {
      return this.customClasses.join(' ')
    }
  },
  mounted() {
    console.log('Component price has been mounted')
    console.log('mounted -> this.value', this.value)
  },
  methods: {
    handleInput(event) {
      this.$emit('input', parseFloat(event.target.value))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
