<template>
  <app-modal-template>
    <template slot="title">
      <h1>DMP</h1>
    </template>
    <template slot="content">
      <form id="dmpForm" novalidate @submit.prevent>
        <b-field label="Generales"></b-field>
        <b-field position="is-right" grouped>
          <b-checkbox :true-value="1" :false-value="0" v-model="appEnabled" type="is-accent" size="is-small">Habilitada</b-checkbox>
        </b-field>
        <b-field :custom-class="formatedClasses" label="Tipo de Cliente">
          <b-select v-model="selectedClientType" size="is-small" placeholder="Selecciona un tipo de cliente" required expanded icon="user">
            <option v-for="clientType in clientTypes" :key="clientType.id" :value="clientType.id">{{ clientType.type }}</option>
          </b-select>
        </b-field>

        <b-field :custom-class="formatedClasses" label="URL">
          <b-input v-model="appURL" name="url" size="is-small" placeholder="Dirección URL para DMP"></b-input>
        </b-field>
        <b-field :custom-class="formatedClasses" label="Lenguaje">
          <b-select v-model="selectedLanguage" size="is-small" placeholder="Selecciona un lenguaje" required expanded icon="language">
            <option v-for="language in languages" :key="language.id" :value="language.shortHand">{{ language.name }}</option>
          </b-select>
        </b-field>
        <b-field :custom-class="formatedClasses" label="Moneda">
          <b-select v-model="selectedCurrency" size="is-small" placeholder="Selecciona una moneda" required expanded icon="dollar-sign">
            <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
          </b-select>
        </b-field>
        <div v-if="!this.app.hasOwnProperty('data')" style="display: block; margin-top: 2%; margin-bottom: 2%;">
        <b-field :custom-class="formatedClasses" label="Parent pricing rules"></b-field>
        <b-field position="is-left" grouped>
          <b-checkbox :true-value="1" :false-value="0" v-model="applyParentPrice" type="is-accent" size="is-small">Apply parent pricing rules</b-checkbox>
        </b-field>
        </div>
        <b-field
          :custom-class="formatedClasses"
          :type="errors.has('radio_shared_data') ? 'is-danger' : ''"
          :message="errors.first('radio_shared_data')"
          label="Shared audiences"
        >
          <div class="control">
            <b-radio v-validate="'required'" v-model="shareData" :native-value="true" name="radio_shared_data" size="is-small"
              >Habilitada</b-radio
            >
            <b-radio v-model="shareData" :native-value="false" name="radio_shared_data" size="is-small">Deshabilitada</b-radio>
          </div>
        </b-field>
        <b-field label="Plugins"></b-field>
        <b-field :custom-class="formatedClasses">
          <b-field grouped group-multiline position="is-left">
            <!-- <b-checkbox-button v-model="dmpTools.shareData"
              :native-value="true"
              size="is-small"
              type="is-success">
              <b-icon icon="share-alt"></b-icon>
              <span>Share Data</span>
            </b-checkbox-button>-->
            <b-checkbox-button v-model="dmpTools.mockingbird" :native-value="true" size="is-small" type="is-success">
              <b-icon icon="crow"></b-icon>
              <span>Mocking Bird</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="dmpTools.earningsEnabled" :native-value="true" size="is-small" type="is-success">
              <b-icon icon="dollar-sign"></b-icon>
              <span>Earnings</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="dmpTools.brandedData" :native-value="true" size="is-small" type="is-success">
              <b-icon icon="certificate"></b-icon>
              <span>Branded Data</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="dmpTools.allowAllDataQuery" :native-value="true" size="is-small" type="is-success">
              <b-icon icon="globe"></b-icon>
              <span>All Data Query</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="dmpTools.calculatorEnabled" :native-value="true" size="is-small" type="is-success">
              <b-icon icon="calculator"></b-icon>
              <span>Calculadora Planner</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="dmpTools.adServerIntegration" :native-value="true" size="is-small" type="is-success">
              <b-icon icon="server"></b-icon>
              <span>Ad Servers Integration</span>
            </b-checkbox-button>
          </b-field>
        </b-field>
        <b-field label="Presentación"></b-field>
        <b-field :custom-class="formatedClasses" label="Theme">
          <b-select v-model="selectedTheme" size="is-small" placeholder="Seleccione un theme" required expanded icon="palette">
            <option v-for="theme in organizationAppThemes" :key="theme.id" :value="theme.id">{{ theme.name | appNames }}</option>
          </b-select>
        </b-field>
        <b-field :class="{ 'has-name': !!image }" class="file is-info" position="is-left">
          <b-upload v-model="image" class="file-label" name="file" expanded>
            <a class="file-cta button is-info is-small">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Agregar Imagen de Header</span>
            </a>
            <span v-if="image" class="file-name is-size-7">
              {{ image.name }}
            </span>
          </b-upload>
          <!-- <span v-if="image && image.length" class="file-name is-size-7">{{ image[0].name }}</span> -->
        </b-field>
      </form>
    </template>
    <template slot="buttons">
      <button class="button" @click="formModalCancel">Cancelar</button>
      <button :class="{ 'is-loading': showLoader }" class="button is-accent" @click="onDMPSetConfig">Aceptar</button>
    </template>
  </app-modal-template>
</template>

<script>
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'
import PriceInput from './PriceInput'
import { mapGetters, mapActions } from 'vuex'
import { formMixin } from '@/mixins/formMixin'

export default {
  name: 'dmpForm',
  components: {
    'app-modal-template': ModalTemplate,
    'price-input': PriceInput
  },
  mixins: [formMixin],
  props: {
    id: {
      type: String,
      default: ''
    },
    app: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      customClasses: ['has-text-grey-dark', 'is-size-7', 'has-text-weight-light'],
      image: null,
      currencies: [
        { id: 1, name: 'Pesos Argentinos' },
        { id: 2, name: 'Pesos Chilenos' },
        { id: 3, name: 'Dólar Estadounidense' },
        { id: 4, name: 'Pesos Mexicanos' },
        { id: 5, name: 'Pesos Colombianos' },
        { id: 6, name: 'Euros' },
        { id: 7, name: 'Real Brasilero' }
      ],
      languages: [
        { id: 1, name: 'Español', shortHand: 'es' },
        { id: 2, name: 'Inglés', shortHand: 'en' },
        { id: 3, name: 'Portugúes', shortHand: 'pt' }
      ],
      countries: [],
      clientTypes: [{ id: 1, type: 'Publisher' }, { id: 2, type: 'Advertiser' }],
      price: 1,
      appEnabled: 1,
      applyParentPrice: 0,
      appURL: '',
      selectedClientType: 1,
      selectedCurrency: 1,
      selectedTheme: 1,
      selectedLanguage: 'es',
      selectedCountry: null,
      organizationData: {},
      shareData: true,
      dmpTools: {
        brandedData: false,
        calculatorEnabled: false,
        mockingbird: false,
        adServerIntegration: false,
        earningsEnabled: false,
        allowAllDataQuery: false
      }
    }
  },
  computed: {
    ...mapGetters(['organization', 'organizationAppThemes', 'requestSuccess', 'serverError', 'showLoader', 'requestSucces']),
    formatedClasses() {
      return this.customClasses.join(' ')
    },
    themes() {
      return this.organizationAppThemes
    }
  },
  watch: {
    selectedClientType: function(newSelected, oldQuestion) {
      // console.log('TCL: newSelected', newSelected)
      if (newSelected === 2 && this.shareData && !this.app.hasOwnProperty('data')) {
        this.shareData = false
      } else if (newSelected === 1 && !this.shareData && !this.app.hasOwnProperty('data')) {
        this.shareData = true
      }
    }
  },
  mounted() {
    this.getThemesForApps()
    // Detects and set data for edition
    if (this.app.hasOwnProperty('data')) {
      this.prepareDataForEdition()
    }
  },
  methods: {
    ...mapActions(['setAppConfiguration', 'getThemesForApps', 'fetchCountries']),
    onDMPSetConfig() {
      const priceCollection = []
      if (this.selectedClientType === 1) {
        priceCollection.push({ value: this.price, country: 'WW' })
      }

      this.organizationData = {
        id: this.$route.params.id,
        url: this.appURL,
        image: this.image ? this.image : null,
        lang: this.selectedLanguage,
        enabled: this.appEnabled,
        applyParentPricingRules: this.applyParentPrice,
        themeId: this.selectedTheme,
        type: this.selectedClientType,
        currencyId: this.selectedCurrency,
        shareData: this.shareData ? 1 : 0,
        earningsEnabled: this.dmpTools.earningsEnabled ? 1 : 0,
        adServerIntegration: this.dmpTools.adServerIntegration ? 1 : 0,
        mockingbird: this.dmpTools.mockingbird ? 1 : 0,
        brandedData: this.dmpTools.brandedData ? 1 : 0,
        calculatorEnabled: this.dmpTools.calculatorEnabled ? 1 : 0,
        allowAllDataQuery: this.dmpTools.allowAllDataQuery ? 1 : 0
      }

      console.log('onDMPSetConfig -> this.organizationData', this.organizationData)

      this.validateBeforeSubmit([this.setAppConfiguration], {
        appId: this.app.id,
        organization: this.organizationData,
        isEdition: this.app.hasOwnProperty('data')
      })
    },
    prepareDataForEdition() {
      let configuration = { ...this.app.data }
      this.appEnabled = configuration.enabled
      // this.applyParentPrice = !!configuration.applyParentPricingRules
      console.log('prepareDataForEdition -> configuration', configuration)
      // console.log('prepareDataForEdition -> configuration.price.value', configuration.price)
      this.selectedClientType = configuration.type
      this.selectedCurrency = configuration.currencyId
      this.selectedTheme = configuration.themeId
      this.appURL = configuration.url
      this.selectedLanguage = configuration.hasOwnProperty('lang') ? configuration.lang : 'es'
      this.shareData = Boolean(configuration.shareData)
      this.dmpTools = {
        brandedData: !!configuration.brandedData,
        calculatorEnabled: !!configuration.calculatorEnabled,
        mockingbird: !!configuration.mockingbird,
        adServerIntegration: !!configuration.adServerIntegration,
        earningsEnabled: !!configuration.earningsEnabled,
        allowAllDataQuery: !!configuration.allowAllDataQuery
      }
    }
  }
}
</script>

<style scoped>
</style>
