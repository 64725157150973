var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column", attrs: { id: "main-container" } }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "card-content" },
          [
            _vm._l(_vm.rules, function(rule, index) {
              return _c("div", { key: rule.id }, [
                _c(
                  "form",
                  {
                    attrs: { validate: "" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "columns is-desktop" }, [
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("cli-input", {
                            attrs: { condition: rule.selectedRule },
                            model: {
                              value: rule.clients,
                              callback: function($$v) {
                                _vm.$set(rule, "clients", $$v)
                              },
                              expression: "rule.clients"
                            }
                          }),
                          _c("p", { staticClass: "error" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  rule.error.clients ? rule.error.clients : null
                                ) +
                                "\n              "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("plat-input", {
                            model: {
                              value: rule.platforms,
                              callback: function($$v) {
                                _vm.$set(rule, "platforms", $$v)
                              },
                              expression: "rule.platforms"
                            }
                          }),
                          _c("p", { staticClass: "error" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  rule.error.platforms
                                    ? rule.error.platforms
                                    : null
                                ) +
                                "\n              "
                            )
                          ])
                        ],
                        1
                      ),
                      rule.selectedRule === "firstparty"
                        ? _c(
                            "div",
                            { staticClass: "column" },
                            [
                              _c("segment-input", {
                                model: {
                                  value: rule.segmentType,
                                  callback: function($$v) {
                                    _vm.$set(rule, "segmentType", $$v)
                                  },
                                  expression: "rule.segmentType"
                                }
                              }),
                              _c("p", { staticClass: "error" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      rule.error.segmentType
                                        ? rule.error.segmentType
                                        : null
                                    ) +
                                    "\n              "
                                )
                              ])
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "column" },
                            [
                              _c("cluster-input", {
                                model: {
                                  value: rule.clusters,
                                  callback: function($$v) {
                                    _vm.$set(rule, "clusters", $$v)
                                  },
                                  expression: "rule.clusters"
                                }
                              }),
                              _c("p", { staticClass: "error" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      rule.error.clusters
                                        ? rule.error.clusters
                                        : null
                                    ) +
                                    "\n              "
                                )
                              ])
                            ],
                            1
                          ),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("rules-input", {
                            model: {
                              value: rule.selectedRule,
                              callback: function($$v) {
                                _vm.$set(rule, "selectedRule", $$v)
                              },
                              expression: "rule.selectedRule"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column is-2" },
                        [
                          _c(
                            "b-field",
                            { attrs: { label: "Precio" } },
                            [
                              _c("b-numberinput", {
                                attrs: {
                                  min: "0",
                                  size: "is-small",
                                  type: "is-primary",
                                  "controls-position": "compact",
                                  "controls-rounded": "",
                                  step: "0.01"
                                },
                                model: {
                                  value: rule.price,
                                  callback: function($$v) {
                                    _vm.$set(rule, "price", $$v)
                                  },
                                  expression: "rule.price"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { style: { flex: "0", marginTop: "38px" } },
                        [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: "Borrar Regla",
                                position: "is-top",
                                type: "is-primary",
                                size: "is-medium",
                                animated: "",
                                rounded: ""
                              }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  style: { border: "none" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleRemove(index)
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      pack: "fas",
                                      icon: "trash",
                                      size: "is-big",
                                      type: "is-primary"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-full" }, [
                    rule.conflict
                      ? _c(
                          "div",
                          { style: { margin: "15px 0" } },
                          [
                            _c(
                              "b-message",
                              {
                                attrs: {
                                  type: "is-warning",
                                  "has-icon": "",
                                  size: "is-small",
                                  "aria-close-label": "Close message"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                      Se identifica la presencia de un conflicto por similitudes de valores de Regla.\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              ])
            }),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column is-1" },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: "Agregar una Regla",
                        position: "is-right",
                        type: "is-accent",
                        size: "is-small",
                        animated: "",
                        rounded: ""
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "button is-accent",
                          attrs: { type: "button" },
                          on: { click: _vm.handleAdd }
                        },
                        [_c("strong", [_vm._v("+")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          2
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "card-content" },
          [
            _c("b-field", { attrs: { grouped: "", position: "is-right" } }, [
              _c(
                "p",
                { staticClass: "control" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "button",
                      attrs: { to: { name: "pricing" } }
                    },
                    [_c("span", [_vm._v("Cancelar")])]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "control" }, [
                _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "button is-accent",
                      class: { "is-loading": _vm.loading },
                      on: { click: _vm.handleConfirm }
                    },
                    [_c("span", [_vm._v("Confirmar")])]
                  )
                ])
              ])
            ])
          ],
          1
        ),
        [
          _c(
            "div",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.rules, function(rule, index) {
              return _c(
                "div",
                { key: rule.id, staticClass: "column is-one-third" },
                [
                  _c("client-preview", {
                    attrs: {
                      index: index,
                      rule: rule,
                      selectedRule: rule.selectedRule
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "header",
      { staticClass: "card-header is-flex is-flex--space-between" },
      [
        _c("div", [
          _c(
            "p",
            { staticClass: "card-header-title is-size-5 has-text-grey" },
            [_vm._v("Creación de reglas de precio")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }