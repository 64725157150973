<template>
  <div class="columns">
    <div class="column">
      <!-- <transition name="slide" mode="out-in"> -->
      <router-view></router-view>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'organization',
  components: {},
  data() {
    return {}
  }
}
</script>
