var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "app-organization-template",
        [
          _c("template", { slot: "title" }, [
            _c("h2", [_vm._v("Cargar Aplicaciones")]),
            _c("h3", { staticClass: "is-size-6" }, [
              _c("span", { staticClass: "has-text-grey" }, [
                _vm._v("Organización: ")
              ]),
              _c("span", { staticClass: "has-text-weight-bold" }, [
                _vm._v(_vm._s(_vm.organization.name))
              ])
            ])
          ]),
          _c("template", { slot: "content" }, [
            _c(
              "div",
              { staticClass: "columns is-multiline" },
              _vm._l(_vm.filteredByPermissionProducts, function(app, index) {
                return _c("app-organization-app-item", {
                  key: index,
                  attrs: { app: app }
                })
              }),
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.requestOrganizationError,
                    expression: "requestOrganizationError"
                  }
                ],
                staticClass: "content has-text-danger has-text-centered"
              },
              [
                _c("p", [
                  _c("span", { staticClass: "icon is-large" }, [
                    _c("i", { staticClass: "far fa-frown fa-3x" })
                  ])
                ]),
                _c("p", [_vm._v(_vm._s(_vm.$t("errors.requestError")))])
              ]
            )
          ])
        ],
        2
      ),
      _c("b-loading", {
        attrs: {
          "is-full-page": true,
          active: _vm.requestOrganizations,
          "can-cancel": true
        },
        on: {
          "update:active": function($event) {
            _vm.requestOrganizations = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }