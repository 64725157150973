<template>
  <app-modal-template>
    <template slot="title">
      <h1>Match One</h1>
    </template>
    <template slot="content">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quo distinctio cum laboriosam nemo aliquam, voluptatem optio accusamus alias itaque amet, nulla, pariatur possimus officia. Explicabo est nihil accusantium excepturi possimus!
    </template>
    <template slot="buttons">
      <a class="button is-accent">Aceptar</a>
    </template>
  </app-modal-template>
</template>

<script>
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'

export default {
  name: 'm1Form',
  components: {
    'app-modal-template': ModalTemplate
  }
}
</script>

<style scoped>

</style>
