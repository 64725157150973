<template>
  <section>
    <keep-alive>
      <component
        :is="selectedComponent"
        :app="app"
        @on-form-success="showSuccessMessages"
        @on-form-error="showErrorMessages"
        @on-form-processing="clearMessages"
        @on-form-cancel="closeModal"
      ></component>
    </keep-alive>
  </section>
</template>

<script>
import OrganizationDMPForm from './OrganizationDMPForm'
import OrganizationM1Form from './OrganizationM1Form'
import OrganizationIDxForm from './OrganizationIDxForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'appModalRender',
  components: {
    'app-dmp-form': OrganizationDMPForm,
    'app-m1-form': OrganizationM1Form,
    'app-idx-form': OrganizationIDxForm
  },
  inheritAttrs: true,
  props: {
    id: {
      type: String
    },
    app: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      error: { hasError: false, invalidFormError: false },
      success: { hasSuccess: false }
    }
  },
  computed: {
    ...mapGetters(['organization']),
    selectedComponent() {
      switch (this.app.id) {
        case 3: {
          return 'app-dmp-form'
        }
        case 9: {
          return 'app-idx-form'
        }
        default: {
          return 'app-m1-form'
        }
      }
    }
  },
  created() {
    if (Object.keys(this.organization) === 0) {
      this.getOrganizationById(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions(['getOrganizationById']),
    showValue(value) {
      alert(value)
    },
    clearMessages({ ...error }, success = { hasSuccess: false }) {
      this.error = error
      this.success = { ...success }
    },
    showSuccessMessages({ ...success }) {
      this.success = success
      this.$buefy.snackbar.open({ message: `Aplicación configurada para ${this.organization.name}`, type: 'is-success' })
      this.getOrganizationById(this.$route.params.id)
      this.closeModal()
    },
    showErrorMessages({ ...error }) {
      this.error = error
      this.$buefy.snackbar.open({ message: `¡Ups algo salio mal!`, type: 'is-danger' })
    },
    closeModal() {
      this.$parent.close()
    }
  }
}
</script>

<style lang ="scss" scoped>
</style>
