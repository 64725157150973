var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fragment", [
    _c(
      "div",
      { staticClass: "column is-4" },
      [
        _c("div", { staticClass: "card" }, [
          _c("header", { staticClass: "card-header" }, [
            _c("p", { staticClass: "card-header-title" }, [
              _vm._v(_vm._s(_vm.getAppName()))
            ]),
            _c(
              "a",
              {
                staticClass: "card-header-icon",
                attrs: { "aria-label": "more options" }
              },
              [
                _c("span", { staticClass: "icon has-text-accent" }, [
                  _c("i", {
                    staticClass: "fas fa-laptop-code",
                    attrs: { "aria-hidden": "true" }
                  })
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "card-content" }, [
            _c("div", { staticClass: "content" }, [
              _c(
                "p",
                { staticClass: "has-text-right" },
                [
                  _c("span", { staticClass: "has-text-weight-bold" }, [
                    _vm._v("Status: ")
                  ]),
                  _c(
                    "b-tag",
                    {
                      attrs: {
                        type: _vm.app.enabled ? "is-success" : "is-danger"
                      }
                    },
                    [_vm._v(_vm._s(_vm.statusApp))]
                  )
                ],
                1
              )
            ])
          ]),
          _c("footer", { staticClass: "card-footer" }, [
            !_vm.app.enabled
              ? _c("p", { staticClass: "card-footer-item" }, [
                  _c(
                    "a",
                    {
                      ref: "activator",
                      staticClass: "button is-white",
                      attrs: { disabled: !_vm.isConfigured },
                      on: {
                        click: function($event) {
                          return _vm.invokeProductActivation(0, $event)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "icon has-text-success" }, [
                        _c("i", { staticClass: "fas fa-check" })
                      ]),
                      _c("span", [_vm._v("Activar")])
                    ]
                  )
                ])
              : _c("p", { staticClass: "card-footer-item" }, [
                  _c(
                    "a",
                    {
                      ref: "activator",
                      staticClass: "button is-white",
                      on: {
                        click: function($event) {
                          return _vm.invokeProductActivation(1, $event)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "icon has-text-danger" }, [
                        _c("i", { staticClass: "fas fa-ban" })
                      ]),
                      _c("span", [_vm._v("Desactivar")])
                    ]
                  )
                ]),
            _c("p", { staticClass: "card-footer-item" }, [
              _c(
                "a",
                {
                  ref: "config",
                  staticClass: "button is-white",
                  attrs: { disabled: _vm.isNotConfigurable },
                  on: { click: _vm.tryConfigurate }
                },
                [
                  _c("span", { staticClass: "icon has-text-info" }, [
                    _c("i", { staticClass: "fas fa-cogs" })
                  ]),
                  _c("span", [_vm._v("Configurar")])
                ]
              )
            ])
          ])
        ]),
        _c(
          "b-modal",
          {
            attrs: {
              active: _vm.modalActive,
              canCancel: ["x", "outside"],
              "has-modal-card": ""
            },
            on: {
              "update:active": function($event) {
                _vm.modalActive = $event
              }
            }
          },
          [_c("app-modal-render", { attrs: { app: _vm.app } })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }