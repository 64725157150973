var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal-template",
    [
      _c("template", { slot: "title" }, [_c("h1", [_vm._v("IDx UI")])]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            attrs: { id: "idxForm", novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("b-field", { attrs: { label: "Requeridas" } }),
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c(
                  "b-field",
                  {
                    attrs: { "custom-class": _vm.formatedClasses, label: "IDx" }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        readonly: true,
                        name: "mask",
                        size: "is-small",
                        placeholder: ""
                      },
                      model: {
                        value: _vm.mask,
                        callback: function($$v) {
                          _vm.mask = $$v
                        },
                        expression: "mask"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-checkbox",
                  {
                    attrs: {
                      "true-value": true,
                      "false-value": false,
                      type: "is-accent",
                      size: "is-small"
                    },
                    model: {
                      value: _vm.appEnabled,
                      callback: function($$v) {
                        _vm.appEnabled = $$v
                      },
                      expression: "appEnabled"
                    }
                  },
                  [_vm._v("\n          IDx habilitado\n        ")]
                )
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      "custom-class": _vm.formatedClasses,
                      label: "Nombre"
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "name",
                        size: "is-small",
                        placeholder: "Nombre"
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      "custom-class": _vm.formatedClasses,
                      label: "Tipo de Proveedor"
                    }
                  },
                  [
                    _c(
                      "b-select",
                      {
                        attrs: {
                          size: "is-small",
                          placeholder: "Selecciona un tipo de proveedor",
                          required: "",
                          expanded: "",
                          icon: "user"
                        },
                        model: {
                          value: _vm.selectedProviderType,
                          callback: function($$v) {
                            _vm.selectedProviderType = $$v
                          },
                          expression: "selectedProviderType"
                        }
                      },
                      _vm._l(_vm.providerTypes, function(providerType) {
                        return _c(
                          "option",
                          {
                            key: providerType.id,
                            domProps: { value: providerType.id }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(providerType.type) +
                                "\n            "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("b-field", { attrs: { label: "Opcionales" } }),
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      "custom-class": _vm.formatedClasses,
                      label: "Nombre Corto"
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "shortName",
                        size: "is-small",
                        placeholder: "Nombre corto"
                      },
                      model: {
                        value: _vm.short_name,
                        callback: function($$v) {
                          _vm.short_name = $$v
                        },
                        expression: "short_name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      "custom-class": _vm.formatedClasses,
                      label: "ID Dashboard"
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "dashboardId",
                        size: "is-small",
                        placeholder: "ID Dashboard"
                      },
                      model: {
                        value: _vm.dashboard_id,
                        callback: function($$v) {
                          _vm.dashboard_id = $$v
                        },
                        expression: "dashboard_id"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  "custom-class": _vm.formatedClasses,
                  label: "Clave publica"
                }
              },
              [
                _c("b-input", {
                  attrs: {
                    name: "publicKey",
                    size: "is-small",
                    placeholder: "Clave publica",
                    type: "textarea"
                  },
                  model: {
                    value: _vm.public_key,
                    callback: function($$v) {
                      _vm.public_key = $$v
                    },
                    expression: "public_key"
                  }
                })
              ],
              1
            ),
            _c("b-field", { attrs: { label: "Retargetly Pixel drop" } }),
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: {
                      "true-value": 1,
                      "false-value": 0,
                      type: "is-accent",
                      size: "is-small"
                    },
                    model: {
                      value: _vm.dropPixelEnabled,
                      callback: function($$v) {
                        _vm.dropPixelEnabled = $$v
                      },
                      expression: "dropPixelEnabled"
                    }
                  },
                  [_vm._v("\n          Pixel Drop Habilitado\n        ")]
                )
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      "custom-class": _vm.formatedClasses,
                      label: "Source"
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "idSource",
                        size: "is-small",
                        placeholder: "ID source"
                      },
                      model: {
                        value: _vm.id_source,
                        callback: function($$v) {
                          _vm.id_source = $$v
                        },
                        expression: "id_source"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: { "custom-class": _vm.formatedClasses, label: "Url" }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "url",
                        size: "is-small",
                        placeholder: "Url"
                      },
                      model: {
                        value: _vm.url,
                        callback: function($$v) {
                          _vm.url = $$v
                        },
                        expression: "url"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("b-field", { attrs: { label: "Pixel" } }, [
              _c(
                "div",
                {
                  attrs: { id: "pixelArea", title: "Copiar al portapapeles" },
                  on: { click: _vm.copyTagToClipboard }
                },
                [
                  _c("b-input", {
                    attrs: { type: "textarea", disabled: "" },
                    model: {
                      value: _vm.pixel,
                      callback: function($$v) {
                        _vm.pixel = $$v
                      },
                      expression: "pixel"
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ]),
      _c("template", { slot: "buttons" }, [
        _c(
          "button",
          { staticClass: "button", on: { click: _vm.formModalCancel } },
          [_vm._v("Cancelar")]
        ),
        _c(
          "button",
          {
            staticClass: "button is-accent",
            class: { "is-loading": _vm.showLoader },
            on: { click: _vm.onIDxSetConfig }
          },
          [_vm._v("Aceptar")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }